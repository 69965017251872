import React from "react";
import CategoryAritlcesBlock from "components/organisims/articles/CategoryAritlcesBlock";
import Container from "components/atoms/Container";
import RightLinks from "components/uikit/Header/HeaderLinks";
import Cover from "../../atoms/Cover";
import HeaderCategories from "components/uikit/Header/HeaderCategories";
import Header from "components/uikit/Header/Header";
import { useMediaQuery } from "@material-ui/core";

type Props = {
  articles: any;
  category: any;
  categories: any;
};

export default function Page(props: Props) {
  const { category, articles, categories } = props;
  const isMobile = useMediaQuery("(max-width: 960px)");

  return (
    <>
      <Header
        color="white"
        brand="Kazi ONLINE"
        fixed
        leftLinks={<HeaderCategories categories={categories} />}
        rightLinks={<RightLinks categories={categories} />}
        changeColorOnScroll={{
          height: 20,
          color: "white",
        }}
      />

      <div style={{ paddingTop: isMobile ? 162 : 121 }}>
        {category.mainImage && category.url && (
          <a href={category.url} target="_bank">
            <Cover
              // @ts-ignore
              src={category.mainImage.url}
              height={33}
              isS3
              style={{ cursor: "pointer" }}
            />
          </a>
        )}

        {category.mainImage && !category.url && (
          // @ts-ignore
          <Cover src={category.mainImage.url} height={33} isS3 />
        )}

        <Container style={{ boxShadow: "none" }}>
          <div style={{ paddingBottom: "40px", paddingTop: "40px" }}>
            <div style={{ textAlign: "center" }}>
              <h2
                style={{
                  fontSize: "32px",
                  fontWeight: "bold",
                }}
              >
                {category.name}
              </h2>
              <div style={{ fontSize: "12px" }}>{category.jName}</div>
              <div
                style={{
                  textAlign: "left",
                  fontSize: "12px",
                  padding: "12px",
                  marginBottom: "12px",
                }}
              >
                {category.description}
              </div>
            </div>

            <CategoryAritlcesBlock
              showTitle={false}
              category={category}
              articles={articles}
            />
          </div>
        </Container>
      </div>
    </>
  );
}
