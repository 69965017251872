import * as React from "react";
import "@aws-amplify/ui/dist/style.css";
import Layout from "components/layouts/Common";
import { Helmet } from "react-helmet";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Category from "components/pages/front/Category";
import { graphql } from "gatsby";
import { getCfUrl } from "settings/storageSettings";
import { listArticleByStatusCategory } from "graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import {
  Article,
  ListArticleByStatusCategoryQuery,
  ModelSortDirection,
} from "API";
import { GraphQLResult } from "@aws-amplify/api-graphql";

type Props = {
  data: any;
  pageContext: any;
};

export default function App(props: Props) {
  const { pageContext, data } = props;
  const { category, categories, footers } = pageContext;
  let articles = data.appsync.listArticleByStatusCategory
    ? data.appsync.listArticleByStatusCategory.items
    : [];

  const [myArticle, setMyArticle] = React.useState<Article[]>(articles);

  let myNextToken = data.appsync.listArticleByStatusCategory.nextToken;

  async function get() {
    // 未来の記事を除外
    articles = articles.filter((article: any) => {
      return article.startDateTime <= new Date().toISOString();
    });

    while (myNextToken) {
      const res = (await API.graphql(
        graphqlOperation(listArticleByStatusCategory, {
          filter: { status: { eq: "publish" } },
          sortDirection: ModelSortDirection.DESC,
          categoryID: category.id,
          startDateTime: { le: new Date().toISOString() },
          nextToken: myNextToken,
        })
      )) as GraphQLResult<ListArticleByStatusCategoryQuery>;
      articles = articles.concat(res.data?.listArticleByStatusCategory?.items);
      myNextToken = res.data?.listArticleByStatusCategory?.nextToken;
    }

    setMyArticle(articles);
  }

  React.useEffect(() => {
    get();
    // eslint-ignore-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GatsbySeo
        title={`${category.name} 舵オンライン │ 船遊びの情報サイト`}
        description={category.description}
        openGraph={{
          url: `https://www.kazi-online.com/categories/${category.slug}`,
          title: `${category.name} 舵オンライン │ 船遊びの情報サイト`,
          description: category.description,
          site_name: "舵オンライン │ 船遊びの情報サイト",
          type: "website",
          images: [
            {
              url: category.mainImage ? getCfUrl(category.mainImage.url) : "",
              alt: category.name,
            },
          ],
        }}
      />
      <Helmet>
        <title>{category.name} 舵オンライン │ 船遊びの情報サイト</title>
      </Helmet>
      <Layout footers={footers}>
        <Category
          category={category}
          categories={categories}
          articles={myArticle}
        />
      </Layout>
    </>
  );
}

export const query = graphql`
  query($categoryID: ID) {
    appsync {
      listArticleByStatusCategory(
        categoryID: $categoryID
        filter: { status: { eq: "publish" } }
        sortDirection: DESC
        limit: 300
      ) {
        nextToken
        items {
          mainImage {
            url
            name
          }
          adID
          authorID
          categoryID
          createdAt
          description
          endDateTime
          id
          slug
          startDateTime
          status
          title
          updatedAt
          version
        }
      }
    }
  }
`;
